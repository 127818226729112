import React, { lazy, Suspense } from "react";
import ReactDOM from "react-dom/client";

import AppLoader from "./AppLoader";
import "./index.css";

const App = lazy(() => import("./App"));

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
	<Suspense fallback={<AppLoader />}>
		<App />
	</Suspense>
);
